import React, { useState } from 'react';

const NavigationBar = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const chartTypes = [
        { title: 'All Costs', description: 'Total financial outlays in buying and selling a home.' },
        { title: 'Net Worth', description: 'Individual\'s financial value fluctuation due to property transactions.' },
        { title: 'Income', description: 'Financial gains versus expenditures in home transactions.' },
        { title: 'Non-Recoverable Costs', description: 'Expenses that cannot be recuperated in transactions.' },
        { title: 'Cost to Liquidate', description: 'Financial implications of converting property to cash.' },
        { title: 'Recovered Costs', description: 'Expenses recouped during property transactions.' },
        { title: 'Tax Savings', description: 'Potential tax benefits from property transactions.' },
    ];

    return (
      <nav className="bg-black text-white p-4">
        <div className="container mx-auto flex items-center justify-between">
          <div className="flex items-center space-x-6">
            <div className="text-xl font-bold mr-8">BuyVsSell</div>
            <a href="/user" className="hover:text-gray-300">Features</a>
            <div className="relative inline-block">
              <button 
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="hover:text-gray-300 focus:outline-none"
              >
                Charts
                <span className="ml-1">▼</span>
              </button>
              {isDropdownOpen && (
                <div className="absolute z-10 mt-2 w-[800px] bg-black rounded-md shadow-lg grid grid-cols-3 gap-4 p-4">
                  {chartTypes.map((chart, index) => (
                    <a
                      key={index}
                      href="/user"
                      className="block text-white hover:bg-gray-800 p-2 rounded"
                      onClick={() => {
                        // Handle chart selection
                        setIsDropdownOpen(false);
                      }}
                    >
                      <h3 className="text-lg font-semibold">{chart.title}</h3>
                      <p className="text-sm text-gray-400">{chart.description}</p>
                    </a>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <a href="/user" className="hover:text-gray-300">Support</a>
            <a href="/user" className="w-10 h-10 bg-gray-300 rounded-full flex items-center justify-center hover:bg-gray-400">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
            </a>
          </div>
        </div>
      </nav>
    );
  };

export default NavigationBar;

