import React from 'react';
import './index.css';
import NavigationBar from './components/navigationbar';
import Sidebar from './components/options';
import LineChart from './components/chart';

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <NavigationBar />
      <main className="flex-grow bg-gray-900 flex flex-col md:flex-row">
        <div className="w-full md:w-1/4 mb-4 md:mb-0">
          <Sidebar />
        </div>
        <div className="w-full md:w-3/4 p-4">
          <LineChart />
        </div>
      </main>
    </div>
  );
}

export default App;