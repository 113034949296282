import React from 'react';

const sidebarItems = [
  'Property Purchase',
  'Property Location & Type',
  'Property Costs',
  'Rental Location & Type',
  'Rental Costs',
  'Taxes',
  'Income',
  'Cash Flow Decisions'
];

function Sidebar() {
  return (
    <div className="w-64 bg-gray-900 text-white p-4">
      {sidebarItems.map((item, index) => (
        <div key={index} className="flex justify-between items-center py-2 px-3 cursor-pointer hover:bg-gray-800">
          <span>{item}</span>
          <span className="text-xs">▼</span>
        </div>
      ))}
    </div>
  );
}

export default Sidebar;
